import {createTheme} from '@mui/material';

import theme from '../WebAppTheme';

const cmsTheme = createTheme({
  ...theme,
  palette: {
    ...theme.palette,
    pageHeaderBackground: '#E5E5E5',
  },
});

export default cmsTheme;
