import {createTheme, responsiveFontSizes} from '@mui/material/styles';
import {spaceBottomSmall, spaceBottom} from './useSharedStyles';

const stroke = '#EBEBEB';

let initialTheme = createTheme();
let theme = createTheme({
  ...initialTheme,
  palette: {
    primary: {
      main: '#e80e51',
      pale: '#FCD9E4',
    },
    secondary: {
      main: '#FFFFFF',
      base: '#E80E51',
      pale: '#ECD4E8',
    },
    stroke: {
      main: stroke,
    },
    pageHeaderBackground: '#FDECF2',
  },
  typography: {
    fontFamily: '"Lato", sans-serif',
    fontSize: 14,
    htmlFontSize: 16,
    lineHeight: '150%',
    allVariants: {
      color: '#000000',
    },
    h1: {
      fontSize: 33,
      fontWeight: 'normal',
    },
    h2: {
      fontSize: 27,
      fontWeight: '500',
    },
    h3: {
      fontSize: 23,
      fontWeight: '500',
    },
    h4: {
      fontSize: 19,
      fontWeight: '500',
    },
    h5: {
      fontSize: 16,
      fontWeight: '500',
    },
    subtitle1: {
      fontSize: '1.1rem',
      fontWeight: initialTheme.typography.fontWeightRegular,
    },
    subtitle2: {
      fontSize: '0.875rem',
      fontWeight: initialTheme.typography.fontWeightMedium,
    },
    body1: {
      fontSize: 16,
      fontWeight: '400',
    },
  },
  shape: {
    borderRadius: 10,
  },
  components: {
    MuiTypography: {
      styleOverrides: {
        h1: initialTheme.unstable_sx(spaceBottom),
        h2: initialTheme.unstable_sx(spaceBottom),
        h3: initialTheme.unstable_sx(spaceBottom),
        h4: initialTheme.unstable_sx(spaceBottom),
        h5: initialTheme.unstable_sx(spaceBottomSmall),
        h6: initialTheme.unstable_sx(spaceBottomSmall),
        subtitle1: initialTheme.unstable_sx(spaceBottomSmall),
        subtitle2: initialTheme.unstable_sx(spaceBottomSmall),
      },
    },
    MuiInputBase: {
      styleOverrides: {
        input: {
          '&::placeholder': {
            fontSize: '1rem',
            color: '#323232',
            letterSpacing: 0.5,
          },
        },
      },
    },
    MuiFormHelperText: {
      styleOverrides: {
        root: {
          fontSize: '0.875rem',
          letterSpacing: 0.25,
          marginBottom: initialTheme.spacing(1),
        },
      },
    },
    MuiFormControl: {
      styleOverrides: {
        root: {
          marginTop: initialTheme.spacing(1),
          marginBottom: initialTheme.spacing(1),
        },
      },
    },
    MuiButton: {
      styleOverrides: {
        root: {
          textTransform: 'none',
          letterSpacing: 0.25,
          fontSize: 16,
        },
      },
    },
    MuiListItemText: {
      styleOverrides: {
        secondary: {
          color: 'black',
        },
      },
    },
    MuiTab: {
      styleOverrides: {
        textColorInherit: {
          color: 'black',
          opacity: 1,
        },
        root: {
          '&.Mui-selected': {
            backgroundColor: 'white',
          },
        },
      },
    },
    MuiTooltip: {
      styleOverrides: {
        tooltip: {
          fontSize: 16,
        },
      },
    },
    MuiCard: {
      styleOverrides: {
        root: {
          width: '100%',
        },
      },
      variants: [
        {
          props: {variant: 'fullscreen'},
          style: {
            borderTopLeftRadius: 0,
            borderTopRightRadius: 0,
          },
        },
      ],
    },
    MuiCardHeader: {
      styleOverrides: {
        root: {
          borderBottomWidth: 1,
          borderBottomStyle: 'solid',
          borderBottomColor: stroke,
        },
        title: {
          marginBottom: 0,
        },
      },
      defaultProps: {
        titleTypographyProps: {variant: 'h3'},
      },
    },
    MuiDialogTitle: {
      styleOverrides: {
        root: {
          marginBottom: 0,
        },
      },
    },
  },
});
theme = responsiveFontSizes(theme);

export default theme;
