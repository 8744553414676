import {Route} from 'react-router-dom';
import {lazy, Suspense} from 'react';

import '../App.css';
import SentryRoutes from '../SentryRoutes';
import WebAppLogin from '../screens/WebAppLogin';
import {useAuth} from '../context/WebAppAuthProvider';
import ForgotPassword from '../screens/ForgotPassword';
import Loading from '../components/Loading';
import {titleCase} from '../util';
import AppContainer from '../components/AppContainer';

// The unauthenticated web app is identical for venue/org users
const UnauthenticatedApp = () => (
  <SentryRoutes>
    <Route path="/login" element={<WebAppLogin />} />
    <Route path="/forgot-password" element={<ForgotPassword />} />
    <Route path="*" element={<WebAppLogin showSignUp={false} />} />
  </SentryRoutes>
);

const CMSWebApp = () => {
  // Once the user is authenticated, render the correct app for their role
  const {isCheckingAuth, isAuthenticated, cmsType} = useAuth();
  if (isCheckingAuth) {
    return <Loading />;
  } else if (!isAuthenticated) {
    return (
      <AppContainer>
        <UnauthenticatedApp />
      </AppContainer>
    );
  } else if (cmsType) {
    const cmsTypeTitleCase = titleCase(cmsType);
    // ModuleSpecifiers must be string (or template, apparently) literals - so we can't lazy load above this point (i.e. not for UnauthenticatedApp)
    // because that would require an expression
    // https://262.ecma-international.org/6.0/#sec-imports
    const CMSWebAppLazy = lazy(
      () => import(`../${cmsTypeTitleCase}WebApp/${cmsTypeTitleCase}WebApp`),
    );
    return (
      <Suspense fallback={<Loading />}>
        <CMSWebAppLazy />
      </Suspense>
    );
  }
};

export default CMSWebApp;
