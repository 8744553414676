// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
___CSS_LOADER_EXPORT___.push([module.id, "@import url(https://fonts.googleapis.com/css2?family=Lato:wght@300;400;700&display=swap);"]);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `html {
  min-height: 100%;
}
body {
  height: 100vh;
  overflow-x: hidden;
  overflow-y: scroll;
}

.form-container {
  padding-top: 37px;
  padding-bottom: 37px;
}
.form-panel-container {
  position: relative;
  margin-top: 64px;
}
.form-panel {
  max-width: 100%;
  position: relative;
  padding-top: 70px;
}
`, "",{"version":3,"sources":["webpack://./src/App.css"],"names":[],"mappings":"AAEA;EACE,gBAAgB;AAClB;AACA;EACE,aAAa;EACb,kBAAkB;EAClB,kBAAkB;AACpB;;AAEA;EACE,iBAAiB;EACjB,oBAAoB;AACtB;AACA;EACE,kBAAkB;EAClB,gBAAgB;AAClB;AACA;EACE,eAAe;EACf,kBAAkB;EAClB,iBAAiB;AACnB","sourcesContent":["@import url('https://fonts.googleapis.com/css2?family=Lato:wght@300;400;700&display=swap');\n\nhtml {\n  min-height: 100%;\n}\nbody {\n  height: 100vh;\n  overflow-x: hidden;\n  overflow-y: scroll;\n}\n\n.form-container {\n  padding-top: 37px;\n  padding-bottom: 37px;\n}\n.form-panel-container {\n  position: relative;\n  margin-top: 64px;\n}\n.form-panel {\n  max-width: 100%;\n  position: relative;\n  padding-top: 70px;\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
