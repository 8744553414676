export const spaceBottomSmall = {
  mb: 1,
};
export const spaceBottom = {
  mb: 2,
};
export const indent = {
  ml: 2,
};

// These them declarations need to be theme-aware
// TODO - styled components or theme variants?
// <TextareaAutosize> doesn't support sx, so this is just style
export const textArea = {
  maxWidth: '100%',
  padding: 1 * 8,
  lineHeight: 1.5,
  fontSize: 16,
  color: 'black',
  resize: 'none',
};
export const codeTextArea = {
  ...textArea,
  cursor: 'pointer',
  backgroundColor: '#272c34',
  color: 'white',
};

export const copyButton = {
  minWidth: 170,
  whiteSpace: 'pre',
  flexShrink: 0,
};

export const leftCard = {
  mr: 2,
};
export const rightCard = {
  ml: 2,
};
export const topCard = {
  mb: 3,
};

export const multiline = {
  whiteSpace: 'pre-wrap',
};
