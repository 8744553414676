import {Cookies, getCookieConsentValue, defaultCookieConsentName} from 'react-cookie-consent';

export const rejectIfFailed = (response) => {
  if (!response.ok) {
    return response.json().then((response) => Promise.reject(response));
  }
  return response;
};

export const isToday = (date) => {
  const today = new Date();
  return (
    date.getDate() === today.getDate() &&
    date.getMonth() === today.getMonth() &&
    date.getFullYear() === today.getFullYear()
  );
};

export const isTomorrow = (date) => {
  const tomorrow = new Date();
  tomorrow.setDate(tomorrow.getDate() + 1);
  return (
    date.getDate() === tomorrow.getDate() &&
    date.getMonth() === tomorrow.getMonth() &&
    date.getFullYear() === tomorrow.getFullYear()
  );
};

export const isPreviousDate = (date) => {
  const today = new Date();
  today.setHours(0, 0, 0, 0);
  return date < today;
};

export const canBookSlot = (slot, currentTime, noticeHours = 0) => {
  // should we offer the user this booking slot, taking into account the current time and notice period?
  const [slotHour, slotMinutes] = slot.split(':').map((value) => parseInt(value, 10));
  const [currentHour, currentMinutes] = currentTime.split(':').map((value) => parseInt(value, 10));
  return (
    slotHour > currentHour + noticeHours ||
    (slotHour === currentHour + noticeHours && slotMinutes >= currentMinutes)
  );
};

export const dateAndTimeComparison = (datetimeA, datetimeB) => datetimeA - datetimeB;

export const round = (value) => Math.round((value + Number.EPSILON) * 1000) / 1000;

export const range = (start, end) => {
  var a = [start],
    b = start;
  while (b < end) {
    a.push(b++);
  }
  return a;
};

export const validateWebsite = (website) => {
  if (website === '') {
    return true;
  }
  try {
    new URL(website);
  } catch (_) {
    return false;
  }
  return true;
};

export const arrivalToString = (arrival, arrivalEnd) => {
  // The date we get from laravel isn't in a correct ISO format
  // Temporary fix - replace the space between date and time with a 'T'
  const arrivalDateTime = new Date(arrival.replace(' ', 'T'));
  let arrivalDate = arrivalDateTime.toDateString();
  if (isToday(arrivalDateTime)) {
    arrivalDate = 'Today';
  } else if (isTomorrow(arrivalDateTime)) {
    arrivalDate = 'Tomorrow';
  }
  let arrivalTime = arrivalDateTime.toLocaleTimeString([], {
    hour: '2-digit',
    minute: '2-digit',
  });
  if (arrivalEnd) {
    arrivalTime +=
      '-' +
      new Date(arrivalEnd.replace(' ', 'T')).toLocaleTimeString([], {
        hour: '2-digit',
        minute: '2-digit',
      });
  }
  return `${arrivalDate} - ${arrivalTime}`;
};

export const VISIT_STATUSES = {
  CANCELLED: 'Cancelled',
  DECLINED: 'Declined',
  ARRIVED: 'Arrived',
  DELAYED: 'Running late by',
  PENDING: 'Pending',
  NEARBY: 'Nearby',
  PLANNED: 'Planned',
  ACCEPTED: 'Accepted',
};

// This function is used for web-app users, for whom the `/attends` endpoint
// doesn't include notifications for the visit, so we just use .accepted
export const getVisitStatus = (visit) => {
  const timestamp = '';
  if (visit.accepted === 0) {
    return {
      status: 'Declined',
      delay: 0,
      class: 'status-reject',
      tooltip: 'This visit was declined by the venue',
      timestamp,
    };
  } else if (visit.accepted === 1) {
    return {
      status: VISIT_STATUSES.ACCEPTED,
      delay: 0,
      class: 'status-okay',
      tooltip: 'The visit has been accepted by the venue',
      timestamp,
    };
  } else if (visit.accepted === 2) {
    return {
      status: VISIT_STATUSES.PENDING,
      delay: 0,
      class: 'status-warning',
      tooltip: 'The venue needs to accept or decline this visit',
      timestamp,
    };
  }
};
export const visitHasUnansweredRequiredQuestions = ({store, responses}) => {
  const requiredQuestionsWithoutAnwers = store.questions
    // Get required questions
    .filter(({is_required}) => is_required)
    // No matching response
    .filter(({id}) => !responses.find(({question_id}) => question_id === id));
  return requiredQuestionsWithoutAnwers.length > 0;
};

export const mapVisit = (visit) => ({
  ...visit,
  status: getVisitStatus(visit),
  hasUnansweredVisitQuestions: visitHasUnansweredRequiredQuestions(visit),
});

export const sortVisits = (a, b) => {
  const visitATime = new Date(a.arrival);
  const visitBTime = new Date(b.arrival);
  return dateAndTimeComparison(visitATime, visitBTime);
};

// This function is for CMS users, for whom visits.notifications is a list
// Of all status updates for that visit
export const visitToStatusString = (visit) => {
  function getNotificationTimeForType(notificationType) {
    const notification = visit.notifications.find((notif) => notif.type === notificationType);
    if (notification) {
      return notification.created_at.split(' ')[1];
    }
    return '';
  }

  let notificationTypes = [];
  if (typeof visit.notifications !== 'undefined') {
    notificationTypes = visit.notifications.map((notification) => notification.type);
  }

  let result;
  if (visit.cancelled === 1) {
    result = {
      status: 'Cancelled',
      delay: 0,
      class: 'status-reject',
      tooltip: 'This visit was cancelled by the customer',
      timestamp: getNotificationTimeForType('cancelled'),
    };
    return result;
  }
  if (visit.accepted === 0) {
    result = {
      status: 'Declined',
      delay: 0,
      class: 'status-reject',
      tooltip: 'This visit was declined by the venue - the customer has been informed',
      timestamp: getNotificationTimeForType('declined'),
    };
    return result;
  }
  if (notificationTypes.includes('arrived')) {
    result = {
      status: VISIT_STATUSES.ARRIVED,
      delay: 0,
      class: 'status-okay',
      tooltip: 'The customer has arrived at the venue',
      timestamp: getNotificationTimeForType('arrived'),
    };
    return result;
  }
  if (notificationTypes.includes('arrived-manual')) {
    result = {
      status: VISIT_STATUSES.ARRIVED,
      delay: 0,
      class: 'status-okay',
      tooltip: 'The customer has arrived at the venue',
      timestamp: getNotificationTimeForType('arrived-manual'),
    };
    return result;
  }
  if (visit.delay !== 0) {
    result = {
      status: VISIT_STATUSES.DELAYED,
      delay: visit.delay,
      class: 'status-warning',
      tooltip: 'The customer is running late, but still intends to visit',
      timestamp: getNotificationTimeForType('delayed'),
    };
    return result;
  }
  if (visit.accepted === 2) {
    result = {
      status: VISIT_STATUSES.PENDING,
      delay: 0,
      class: 'status-warning',
      tooltip: 'The customer is waiting for the venue to accept or decline this visit',
      timestamp: getNotificationTimeForType('submit'),
    };
    return result;
  }
  if (notificationTypes.includes('nearby')) {
    result = {
      status: VISIT_STATUSES.NEARBY,
      delay: 0,
      class: 'status-okay',
      tooltip: 'The customer is approaching the venue and will be arriving soon',
      timestamp: getNotificationTimeForType('nearby'),
    };
    return result;
  }
  result = {
    status: VISIT_STATUSES.PLANNED,
    delay: 0,
    class: 'status-okay',
    tooltip: 'The customer plans to visit at this time',
    timestamp: getNotificationTimeForType('accepted'),
  };
  return result;
};

export const capitalise = (string) => string.charAt(0).toUpperCase() + string.slice(1);

export const getSubdomainForRoleNames = (roleNames) => {
  if (roleNames.includes('user')) {
    return 'my';
  } else if (
    roleNames.includes('organisation-admin') ||
    roleNames.includes('venue-group-admin') ||
    roleNames.includes('store-admin') ||
    roleNames.includes('store-user')
  ) {
    return 'app';
  } else if (roleNames.includes('admin')) {
    return 'service';
  }
};

export const getBaseUrlForSubdomain = (subdomain) => {
  return `${window.location.protocol}//${subdomain}.${process.env.APP_DOMAIN}/`;
};

export const getCMSTypeForRoleNames = (roleNames = []) => {
  if (roleNames.includes('organisation-admin')) {
    return 'org';
  } else if (roleNames.includes('venue-group-admin')) {
    return 'group';
  } else if (roleNames.includes('store-admin') || roleNames.includes('store-user')) {
    return 'venue';
  } else if (roleNames.includes('user')) {
    return 'user';
  } else if (roleNames.includes('admin')) {
    return 'admin';
  }
};

export const getAppLinkForVenue = (venueId) => {
  return `${getBaseUrlForSubdomain('my')}venues/${venueId}`;
};
export const getAppLinkForOrgVenues = (orgId) => {
  return `${getBaseUrlForSubdomain('my')}organisations/${orgId}`;
};
export const getAppLinkForGroupVenues = (groupId) => {
  return `${getBaseUrlForSubdomain('my')}groups/${groupId}`;
};
export const getAppListForVenueList = (venueListId) => {
  return `${getBaseUrlForSubdomain('my')}venue-lists/${venueListId}`;
};

export function titleCase(string) {
  return string[0].toUpperCase() + string.slice(1).toLowerCase();
}

let isInIFrame = false;
try {
  isInIFrame = window.self !== window.top;
} catch (e) {
  /* empty */
}
export {isInIFrame};

// If WelcoMe is running inside an iFrame and the user has 3rd party cookies blocked,
// we will be unable to read/write to local storage and thus can't authenticate
let canAccessLocalStorage = false;
try {
  canAccessLocalStorage = window.localStorage && true;
} catch (e) {
  /* empty */
}
export {canAccessLocalStorage};

export const getAverageRating = (feedbacks) => {
  // Don't average feedbacks for text questions
  const ratedFeedbacks = feedbacks.filter(
    (feedback) => feedback.rating !== null && feedback.rating !== undefined,
  );
  const average =
    ratedFeedbacks.reduce((acc, value) => acc + value.rating, 0) / ratedFeedbacks.length;
  return average;
};

export const getNonEssentialCookieConsent = () => getCookieConsentValue(defaultCookieConsentName);

export const setNonEssentialCookieConsent = (val) => Cookies.set(defaultCookieConsentName, val);
