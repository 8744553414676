import {useEffect} from 'react';

const ValidatingForm = ({
  initialFieldValues,
  onFieldValidationChange,
  onFieldChange,
  onSubmit,
  preSubmitValidation,
  children,
}) => {
  // Store a reference for the <form> so we can access form inputs
  let fieldKeys = initialFieldValues ? Object.keys(initialFieldValues) : [];

  // When the component is first rendered, we want to set the initial validation state
  useEffect(() => {
    // Create a model for validation errors based on initialFieldValues
    fieldKeys.forEach((key) => onFieldValidationChange(key, false));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // Check if an event requires updated field data or validation
  const eventMatchesFormField = (event) => {
    if (!event || !event.target) {
      // console.error('no event / event.target');
      return false;
    }
    let {name} = event.target;
    if (!name || !fieldKeys.includes(name)) {
      // console.error('no matching formData prop:', event);
      return false;
    }
    return true;
  };

  // When a <form> 'update' event occurs for an form input:
  // update the corresponding initialFieldValues property
  const handleFormChange = (event) => {
    if (eventMatchesFormField(event)) {
      let {name, value, validity, validationMessage, checked, type} = event.target;
      // if the input is a checkbox, grab the "checked" value
      let actualValue = type === 'checkbox' ? checked : value;
      onFieldChange(name, actualValue);
      onFieldValidationChange(name, !validity.valid ? validationMessage : false);
    }
  };

  // When the <form> invalid or blur event occurs:
  // update our own form validation state
  const handleFieldEvent = (event) => {
    if (eventMatchesFormField(event)) {
      let {name, validity, validationMessage} = event.target;
      onFieldValidationChange(name, !validity.valid ? validationMessage : false);
      if (event.type === 'invalid') {
        event.preventDefault();
      }
    }
  };

  // When the <form> submit event occurs:
  const handleFormSubmit = (event) => {
    event.preventDefault();
    if (!preSubmitValidation || preSubmitValidation()) {
      onSubmit(event);
    }
  };

  return (
    <form
      onChange={handleFormChange}
      onSubmit={handleFormSubmit}
      onInvalid={handleFieldEvent}
      onBlur={handleFieldEvent}>
      {children}
    </form>
  );
};
export default ValidatingForm;
