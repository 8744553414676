import {useState} from 'react';
import FormControl from '@mui/material/FormControl';
import Typography from '@mui/material/Typography';
import TooltipIcon from '../components/TooltipIcon';
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
import {DEFAULT_HEADERS} from '../constants';
import AuthPanel from '../components/AuthPanel';
import PageHeading from '../components/PageHeading';
import BackLink from '../components/BackLink';

const ForgotPassword = () => {
  const [email, setEmail] = useState('');
  const [emailSent, setEmailSent] = useState(false);

  const handleEmailChange = (event) => {
    setEmail(event.target.value);
  };

  const handleSubmit = async () => {
    setEmailSent(false);
    const data = JSON.stringify({email});
    const response = await fetch('/api/password/reset/request', {
      method: 'POST',
      headers: DEFAULT_HEADERS,
      body: data,
    });
    if (response.ok) {
      setEmailSent(true);
    } else {
      alert(
        'There was a problem sending the password reset link. Please check your internet connection and try again.',
      );
    }
  };

  return (
    <AuthPanel>
      <BackLink />
      <PageHeading>Forgot Password</PageHeading>
      <Typography variant="subtitle1" paragraph>
        {"Enter your email address and we'll send instructions to reset your password."}
      </Typography>
      <FormControl fullWidth>
        <Typography component="label" variant="subtitle1" htmlFor="email">
          Email
          <TooltipIcon text={'Please enter your email'} anchorElID="email" />
        </Typography>
        <TextField
          id="email"
          name="email"
          type="email"
          value={email}
          onChange={handleEmailChange}
          required
        />
      </FormControl>
      <Button variant="contained" color="primary" onClick={handleSubmit}>
        Submit
      </Button>
      {emailSent && (
        <Typography color="primary" style={{marginTop: 10}}>
          An email with a password reset link has been sent to {email}
        </Typography>
      )}
    </AuthPanel>
  );
};

export default ForgotPassword;
