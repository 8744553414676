import {createRoot} from 'react-dom/client';
import {BrowserRouter} from 'react-router-dom';
import {ThemeProvider, StyledEngineProvider} from '@mui/material/styles';
import CssBaseline from '@mui/material/CssBaseline';
import {QueryClient, QueryClientProvider} from '@tanstack/react-query';

import '../InitSentry';
import CMSWebApp from './CMSWebApp';
import {AuthProvider} from '../context/WebAppAuthProvider';
import cmsTheme from './CMSWebAppTheme';

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      retry: 0,
    },
  },
});

const container = document.getElementById('cms-web-app');
const root = createRoot(container);
root.render(
  <QueryClientProvider client={queryClient}>
    <StyledEngineProvider injectFirst>
      <ThemeProvider theme={cmsTheme}>
        <AuthProvider>
          <CssBaseline />
          <BrowserRouter>
            <CMSWebApp />
          </BrowserRouter>
        </AuthProvider>
      </ThemeProvider>
    </StyledEngineProvider>
  </QueryClientProvider>,
);
