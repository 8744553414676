const stages = [
  {
    path: '/1/',
    label: 'Sign Up',
  },
  {
    path: '/2/',
    label: 'Set a Password',
  },
  {
    path: '/3/',
    label: 'Venue Details',
  },
  {
    path: '/4/',
    label: 'Opening Hours & Accessibility',
  },
  // {
  //   path: "/5/",
  //   label: "Choose Subscription"
  // }
];

const DEFAULT_HEADERS = {
  'Content-Type': 'application/json',
  Accept: 'application/json',
};

const standardPlan = {
  title: 'Standard',
  features: [
    '£49 Set-up fee',
    '2 Users',
    'Real Time Dashboards',
    'Web Messaging',
    'Email Notifications',
    'Unlimited Chat',
    'User Feedback & Reviews',
  ],
  pricingTitle: '£15',
  pricingSubtitle: 'p/m',
  paymentUrl: 'http://www.welcome-app.com/subscription-standard',
};

const proPlan = {
  title: 'Pro',
  features: [
    '£49 Set-up fee',
    'All Standard features',
    '+',
    '3-5 Users',
    'Phone Notifications',
    'Data Reporting',
  ],
  pricingTitle: '£25',
  pricingSubtitle: 'p/m',
  paymentUrl: 'http://www.welcome-app.com/subscription-premium',
};

const enterprisePlan = {
  title: 'Enterprise',
  features: [
    'All Standard Features',
    '+',
    'Unlimited Users',
    'Phone Notifications',
    'Robust Reporting',
    'Scaleable',
    'Dedicated Account Manager',
  ],
  pricingTitle: 'Custom',
  pricingSubtitle: 'Pricing',
};

const days = ['monday', 'tuesday', 'wednesday', 'thursday', 'friday', 'saturday', 'sunday'];
// move sunday to be in position 0 (to work with Javascript Date.getDay())
let daysSundayLeading = [...days];
daysSundayLeading.unshift(daysSundayLeading.pop());
const defaultFormMargins = {
  x: [3, 5, 8],
  y: [3, 4, 6],
};
const defaultFormSpacingFactor = 1;
export {
  stages,
  standardPlan,
  proPlan,
  enterprisePlan,
  days,
  daysSundayLeading,
  defaultFormMargins,
  defaultFormSpacingFactor,
  DEFAULT_HEADERS,
};

export const visitDateFormatter = (date) =>
  new Intl.DateTimeFormat('default', {
    year: 'numeric',
    month: 'long',
    day: 'numeric',
  }).format(new Date(date));
export const visitTimeFormatter = (date) =>
  new Intl.DateTimeFormat('default', {
    hour: 'numeric',
    minute: 'numeric',
  }).format(new Date(date));

export const RESOURCES_LINK = 'https://helpdesk.wel-co.me/';
export const TRAINING_LINK = 'https://www.wel-co.me/training';

export const MAX_IMAGE_SIZE_MB = 4;
export const MAX_IMAGE_SIZE_BYTES = MAX_IMAGE_SIZE_MB * 1024 * 1024;

export const FINAL_FEEDBACK_QUESTION = 'Do you have any other comments or feedback?';

export const FILE_DROP_ACCEPT = {
  'image/*': ['.png', '.gif', '.jpg', '.jpeg', '.webp', '.apng', '.avif'],
};
