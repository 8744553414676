import Typography from '@mui/material/Typography';
import {useTheme} from '@mui/material/styles';

const PageHeading = ({children, id}) => {
  const theme = useTheme();
  return (
    <Typography
      id={id}
      component="h1"
      variant="h2"
      sx={{
        textDecoration: 'underline',
        // sx doesn't support shorthand for textDecorationColor
        textDecorationColor: theme.palette.primary.main,
        textUnderlineOffset: '4px',
      }}>
      {children}
    </Typography>
  );
};

export default PageHeading;
