import CircularProgress from '@mui/material/CircularProgress';

const Loading = ({padding = true}) => {
  return (
    <div
      style={{
        flex: 1,
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        paddingTop: padding ? '30vh' : undefined,
      }}>
      <CircularProgress aria-label="Loading" />
    </div>
  );
};

export default Loading;
