import React from 'react';
import {
  useLocation,
  useNavigationType,
  createRoutesFromChildren,
  matchRoutes,
} from 'react-router-dom';
import * as Sentry from '@sentry/react';

Sentry.init({
  dsn: process.env.SENTRY_LARAVEL_DSN,
  environment: process.env.APP_ENV,
  integrations: [
    new Sentry.BrowserTracing({
      tracePropagationTargets: [process.env.APP_DOMAIN],
      routingInstrumentation: Sentry.reactRouterV6Instrumentation(
        React.useEffect,
        useLocation,
        useNavigationType,
        createRoutesFromChildren,
        matchRoutes,
      ),
    }),
  ],
  tracesSampleRate: process.env.SENTRY_TRACES_SAMPLE_RATE,
});
