import {useState} from 'react';
import {Link as RouterLink} from 'react-router-dom';
import TextField from '@mui/material/TextField';
import FormControl from '@mui/material/FormControl';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import FormHelperText from '@mui/material/FormHelperText';
import Link from '@mui/material/Link';
import useTheme from '@mui/material/styles/useTheme';

import {useAuth} from '../context/WebAppAuthProvider';
import ValidatingForm from '../components/ValidatingForm';
import TooltipIcon from '../components/TooltipIcon';
import AuthPanel from '../components/AuthPanel';
import PageHeading from '../components/PageHeading';

const WebAppLogin = ({showSignUp = true}) => {
  const theme = useTheme();
  const {login} = useAuth();
  const [fieldErrors, setFieldErrors] = useState({});
  const [fieldValues, setFieldValues] = useState({
    email: '',
    password: '',
  });

  const handleFormSubmit = async () => {
    try {
      const {email, password} = fieldValues;
      await login(email, password);
    } catch (error) {
      alert('There was a problem logging in. Please check your credentials and try again.');
      console.error(error);
    }
  };

  return (
    <AuthPanel>
      <PageHeading>Log In</PageHeading>
      {showSignUp && (
        <Typography variant="subtitle1" paragraph>
          {"Don't have an account? "}
          <Link component={RouterLink} color="primary" to="/signup" underline="hover">
            Sign-up
          </Link>
          .
        </Typography>
      )}
      <ValidatingForm
        onSubmit={handleFormSubmit}
        initialFieldValues={fieldValues}
        onFieldChange={(name, value) => setFieldValues({...fieldValues, [name]: value})}
        onFieldValidationChange={(name, error) => setFieldErrors({...fieldErrors, [name]: error})}>
        <FormControl fullWidth>
          <Typography component="label" variant="subtitle1" htmlFor="email">
            Email
            <TooltipIcon text={'Please enter your email'} />
          </Typography>
          <TextField
            id="email"
            name="email"
            type="email"
            value={fieldValues.email}
            error={!!fieldErrors.email}
            required
          />
          <FormHelperText error={!!fieldValues.email && !!fieldErrors.email}>
            {!!fieldValues.email && fieldErrors.email}
          </FormHelperText>
        </FormControl>
        <FormControl fullWidth>
          <Typography component="label" variant="subtitle1" htmlFor="password">
            Password
            <TooltipIcon text={'Enter your password.'} />
          </Typography>
          <TextField
            id="password"
            name="password"
            type="password"
            value={fieldValues.password}
            error={!!fieldErrors.password}
            autoComplete="off"
            required
          />
          <FormHelperText error={!!fieldErrors.password}>{fieldErrors.password}</FormHelperText>
          <Typography
            component="label"
            variant="subtitle1"
            style={{marginBottom: theme.spacing(1)}}>
            <Link component={RouterLink} to="/forgot-password" color="primary" underline="hover">
              Forgot your password?
            </Link>
          </Typography>
        </FormControl>
        <Button variant="contained" color="primary" type="submit" fullWidth>
          Log In
        </Button>
      </ValidatingForm>
    </AuthPanel>
  );
};

export default WebAppLogin;
