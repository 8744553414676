import {useState, useEffect} from 'react';
import Box from '@mui/material/Box';
import Popper from '@mui/material/Popper';
import Paper from '@mui/material/Paper';
import Typography from '@mui/material/Typography';
import useTheme from '@mui/material/styles/useTheme';
import useMediaQuery from '@mui/material/useMediaQuery';

const diameter = 18;
const borderColor = '#EB004E';

// Copied from https://github.com/mui/material-ui/blob/master/docs/data/material/components/popper/ScrollPlayground.js
const arrowStyle = {
  zIndex: 1,
  '& > div': {
    position: 'relative',
  },
  '&[data-popper-placement*="bottom"]': {
    '& > div': {
      marginTop: 2,
    },
    '& .MuiPopper-arrow': {
      top: 0,
      left: 0,
      marginTop: '-0.9em',
      width: '3em',
      height: '1em',
      '&::before': {
        borderWidth: '0 1em 1em 1em',
        borderColor: `transparent transparent ${borderColor} transparent`,
      },
    },
  },
  '&[data-popper-placement*="top"]': {
    '& > div': {
      marginBottom: 2,
    },
    '& .MuiPopper-arrow': {
      bottom: 0,
      left: 0,
      marginBottom: '-0.9em',
      width: '3em',
      height: '1em',
      '&::before': {
        borderWidth: '1em 1em 0 1em',
        borderColor: `${borderColor} transparent transparent transparent`,
      },
    },
  },
  '&[data-popper-placement*="right"]': {
    '& > div': {
      marginLeft: 2,
    },
    '& .MuiPopper-arrow': {
      left: 0,
      marginLeft: '-0.9em',
      height: '3em',
      width: '1em',
      '&::before': {
        borderWidth: '1em 1em 1em 0',
        borderColor: `transparent ${borderColor} transparent transparent`,
      },
    },
  },
  '&[data-popper-placement*="left"]': {
    '& > div': {
      marginRight: 2,
    },
    '& .MuiPopper-arrow': {
      right: 0,
      marginRight: '-0.9em',
      height: '3em',
      width: '1em',
      '&::before': {
        borderWidth: '1em 0 1em 1em',
        borderColor: `transparent transparent transparent ${borderColor}`,
      },
    },
  },
};

const Arrow = ({arrowRef}) => (
  <Box
    ref={arrowRef}
    className="MuiPopper-arrow"
    sx={{
      position: 'absolute',
      fontSize: 7,
      width: '3em',
      height: '3em',
      '&::before': {
        content: '""',
        margin: 'auto',
        display: 'block',
        width: 0,
        height: 0,
        borderStyle: 'solid',
      },
    }}
  />
);

const TooltipText = ({text, isMd}) => (
  <Paper
    sx={{
      maxWidth: !isMd ? '95vw' : 250,
      padding: 1.5,
      border: `2px solid ${borderColor}`,
      backgroundColor: 'white',
      zIndex: 10,
    }}>
    <Typography>{text}</Typography>
  </Paper>
);

const TooltipIcon = ({text, anchorElID}) => {
  const [anchorEl, setAnchorEl] = useState(null);
  const [arrowRef, setArrowRef] = useState(null);
  const [iconRef, setIconRef] = useState(null);
  const [showTooltip, setShowTooltip] = useState(false);
  const theme = useTheme();
  const isMd = useMediaQuery(theme.breakpoints.up('md'));

  useEffect(() => {
    // When anchorElID changes, update our anchor element
    const anchorElement = document.getElementById(anchorElID);
    if (anchorElement) {
      setAnchorEl(anchorElement);
    } else if (iconRef) {
      setAnchorEl(iconRef);
    }
  }, [anchorElID, iconRef]);

  useEffect(() => {
    // When the anchor element changes, add focus & blur listeners to show/hide tooltip
    if (anchorEl && ['input', 'textarea', 'button'].includes(anchorEl.tagName.toLowerCase())) {
      anchorEl.addEventListener('focus', () => {
        setShowTooltip(true);
      });
      anchorEl.addEventListener('blur', () => {
        setShowTooltip(false);
      });
    }
  }, [anchorEl]);

  return (
    <Box sx={{display: 'inline-block', ml: 1}}>
      <Box
        ref={setIconRef}
        display="flex"
        flexDirection="column"
        justifyContent="center"
        alignItems="center"
        sx={{
          backgroundColor: '#EB004E',
          width: diameter,
          height: diameter,
          borderRadius: '50%',
          color: 'white',
          fontSize: 14,
          fontWeight: 600,
        }}
        onMouseOver={() => setShowTooltip(true)}
        onMouseOut={() => setShowTooltip(false)}>
        ?
      </Box>
      {anchorEl ? (
        <Popper
          anchorEl={anchorEl}
          open={showTooltip}
          placement={isMd ? 'right' : 'bottom'}
          modifiers={[
            {
              name: 'arrow',
              enabled: true,
              options: {
                element: arrowRef,
              },
            },
          ]}
          sx={arrowStyle}>
          <div>
            <Arrow arrowRef={setArrowRef} />
            <TooltipText isMd={isMd} text={text} />
          </div>
        </Popper>
      ) : null}
    </Box>
  );
};

export default TooltipIcon;
