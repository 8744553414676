import {createContext, useContext, useEffect, useState} from 'react';
import {jwtDecode} from 'jwt-decode';
import axios from 'axios';

import {DEFAULT_HEADERS} from '../constants';
import {
  canAccessLocalStorage,
  getSubdomainForRoleNames,
  getBaseUrlForSubdomain,
  getCMSTypeForRoleNames,
} from '../util';

const AuthContext = createContext();

const getDecodedJWTSecondsToExpiry = (decoded) => {
  const expiryDate = new Date(decoded.exp * 1000);
  const secondsUntilExpiry = (expiryDate.getTime() - new Date().getTime()) / 1000;
  return secondsUntilExpiry;
};

const AuthProvider = ({children}) => {
  const [token, setToken] = useState();
  const [user, setUser] = useState();
  const [isCheckingAuth, setIsCheckingAuth] = useState(true);
  const [redirectUrlForAuth, setRedirectUrlForAuth] = useState();

  const isAuthenticated = !!user?.id;
  const AUTHENTICATED_HEADERS = token
    ? {
        Authorization: `Bearer ${token}`,
        ...DEFAULT_HEADERS,
      }
    : {...DEFAULT_HEADERS};

  useEffect(() => {
    if (canAccessLocalStorage) {
      // When the app boots, check for a stored token to kick off the authentication process
      const storageToken = localStorage.getItem('web_app_token');
      if (storageToken) {
        setToken(storageToken);
      } else {
        setIsCheckingAuth(false);
      }
    } else {
      setIsCheckingAuth(false);
    }
  }, []);

  useEffect(() => {
    // If we have a token, check it's valid and fetch user info
    if (token) {
      const decoded = jwtDecode(token);
      if (decoded) {
        if (getDecodedJWTSecondsToExpiry(decoded) < 0) {
          attemptRefreshToken();
        } else {
          // We know the token is valid, store it
          localStorage.setItem('web_app_token', token);
        }
      } else {
        console.error('failed to decode stored token');
        localStorage.removeItem('web_app_token');
        setIsCheckingAuth(false);
      }
    }
  }, [token]);

  // When the token changes, reset the axios interceptors
  useEffect(() => {
    if (token) {
      const requestInterceptor = axios.interceptors.request.use((config) => ({
        ...config,
        headers: {
          ...config.headers,
          Authorization: `Bearer ${token}`,
        },
      }));
      const responseInterceptor = axios.interceptors.response.use(
        (response) => response,
        (axiosError) => {
          if (!handleUnauthorized(axiosError.response)) {
            return Promise.reject(axiosError);
          }
        },
      );
      return () => {
        axios.interceptors.request.eject(requestInterceptor);
        axios.interceptors.response.eject(responseInterceptor);
      };
    }
  }, [token]);

  // If there's a token and no user, fetch the user
  useEffect(() => {
    if (token && !user) {
      (async () => {
        const decoded = jwtDecode(token);
        await getUserInfo(decoded.user.id);
        setIsCheckingAuth(false);
      })();
    }
  }, [token, user]);

  const attemptRefreshToken = async () => {
    const response = await fetch('api/auth/refresh', {
      method: 'PUT',
      headers: AUTHENTICATED_HEADERS,
    });
    if (response.ok) {
      // if response is ok then we have a new token
      const responseJson = await response.json();
      if (responseJson.token) {
        setToken(responseJson.token);
      }
    } else {
      // otherwise the user must log in again
      console.error('failed to refresh token');
      localStorage.removeItem('web_app_token');
      setIsCheckingAuth(false);
    }
  };

  useEffect(() => {
    // Once we're logged in, set a timer to refresh the token before it expires
    if (isAuthenticated && token) {
      const intervalId = setInterval(async () => {
        const decoded = jwtDecode(token);
        const seconds = getDecodedJWTSecondsToExpiry(decoded);
        if (seconds < 60) {
          await attemptRefreshToken();
        }
      }, 10000);
      return () => clearInterval(intervalId);
    }
  }, [isAuthenticated, token]);

  const getUserInfo = async (userId = user.id) => {
    const response = await fetch(`/api/users/${userId}`, {
      headers: AUTHENTICATED_HEADERS,
    });
    if (response.ok) {
      const responseJson = await response.json();
      setUser(responseJson);
    } else {
      if (!handleUnauthorized(response)) {
        alert('failed to fetch user info');
        console.error('failed to fetch user info');
      }
    }
  };

  const register = async (
    name,
    phone,
    email,
    password,
    passwordConfirmation,
    requirements,
    disabilities,
    profile_picture,
    marketingConsent,
    acceptedPrivacyPolicy,
  ) => {
    // backend expects requirements and disabilities to be JSON arrays of IDs
    const requirementsJson = JSON.stringify(requirements.map(({id}) => id));
    const disabilitiesJson = JSON.stringify(disabilities.map(({id}) => id));

    const formData = new FormData();
    if (profile_picture) {
      formData.append('profile_picture', profile_picture);
    }
    formData.append('name', name);
    formData.append('phone', phone);
    formData.append('email', email);
    formData.append('password', password);
    formData.append('passwordConfirmation', passwordConfirmation);
    formData.append('requirements', requirementsJson);
    formData.append('disabilities', disabilitiesJson);
    formData.append('consent_for_marketing', marketingConsent);
    formData.append('accepted_privacy_policy', acceptedPrivacyPolicy);

    const response = await fetch('/api/register', {
      method: 'POST',
      body: formData,
    });
    if (response.ok) {
      const responseJson = await response.json();
      const {token: responseToken} = responseJson;
      setToken(responseToken);
      localStorage.setItem('web_app_token', responseToken);
    }
    return response;
  };

  const login = async (email, password) => {
    const data = JSON.stringify({email, password});
    const response = await fetch('/api/auth', {
      method: 'POST',
      body: data,
      headers: DEFAULT_HEADERS,
    });
    if (response.ok) {
      const responseJson = await response.json();
      const {token: responseToken, user} = responseJson;
      // Check we're on the right subdomain for this user's roles
      const roleNames = user.roles.map((role) => role.name);
      const roleSubdomain = getSubdomainForRoleNames(roleNames);
      const subdomain = window.location.host.split('.')[1]
        ? window.location.host.split('.')[0]
        : false;
      if (subdomain !== roleSubdomain) {
        window.location = getBaseUrlForSubdomain(roleSubdomain);
      } else {
        setToken(responseToken);
        localStorage.setItem('web_app_token', responseToken);
      }
    } else {
      alert('There has been an error logging in - please check your details or try again later.');
    }
  };

  const updateUserProfile = async (updatedUser) => {
    const response = await fetch(`/api/users/${user.id}`, {
      method: 'PUT',
      body: JSON.stringify(updatedUser),
      headers: AUTHENTICATED_HEADERS,
    });
    if (response.ok) {
      const responseJson = await response.json();
      setUser(responseJson);
    }
    return response;
  };

  const updateProfilePicture = async (profilePicture) => {
    const formData = new FormData();
    // See https://github.com/laravel/framework/issues/13457
    // PHP returns empty request body for PUT requests encoded as multipart/form-FormData
    // We can send a POST request and append a '_method' = 'put' field as a workaround.
    formData.append('_method', 'put');
    formData.append('profile_picture', profilePicture);

    const response = await fetch(`/api/users/${user.id}`, {
      method: 'post',
      body: formData,
      headers: {Authorization: `Bearer ${token}`},
    });
    if (response.ok) {
      await getUserInfo();
    }
    return response;
  };

  const logout = async () => {
    await fetch('/api/logout', {
      method: 'POST',
      headers: AUTHENTICATED_HEADERS,
    });
    localStorage.removeItem('web_app_token');
    setToken(undefined);
    setUser(undefined);
  };

  const handleUnauthorized = (response) => {
    if (response.status === 401) {
      alert('Your session has expired, please log in again');
      logout();
      return true;
    } else {
      return false;
    }
  };

  // Venues with appointedd integration require us to pre-fill user information
  // To save duplication we implement this here instead of in both appointedd components
  const nameParts = user?.name?.split(' ') ?? [];
  const appointeddPrefillFields = user
    ? {
        firstName: nameParts[0],
        lastName: nameParts.length > 1 ? nameParts.splice(1, 2).join(' ') : '(not given)',
        mobile: user?.phone ?? '',
        email: user?.email ?? '',
      }
    : undefined;

  // For CMS users, work out which CMS they should see
  const roleNames = user?.roles?.map((role) => role.name);
  const cmsType = getCMSTypeForRoleNames(roleNames);

  // Work out which endpoints to use depending on user role
  const useCMSEndpoints = cmsType && cmsType !== 'user';

  return (
    <AuthContext.Provider
      value={{
        useCMSEndpoints,
        token,
        user,
        roleNames,
        cmsType,
        getUserInfo,
        handleUnauthorized,
        isAuthenticated,
        isCheckingAuth,
        register,
        logout,
        login,
        AUTHENTICATED_HEADERS,
        redirectUrlForAuth,
        setRedirectUrlForAuth,
        updateUserProfile,
        updateProfilePicture,
        appointeddPrefillFields,
      }}>
      {children}
    </AuthContext.Provider>
  );
};

const useAuth = () => useContext(AuthContext);

export {AuthProvider, useAuth};
