import {useNavigate} from 'react-router-dom';
import Button from '@mui/material/Button';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';

const BackLink = ({to, disabled}) => {
  const navigate = useNavigate();
  return (
    <Button
      disabled={disabled}
      variant="text"
      onClick={() => (to ? navigate(to) : navigate(-1))}
      sx={{mb: 2}}
      startIcon={<ChevronLeftIcon />}>
      Back
    </Button>
  );
};

export default BackLink;
