import {Box, Container, Paper} from '@mui/material';
import welcomeLogo from '../images/welcome-logo.png';

const SIDE = 160;
const AuthPanel = ({children}) => (
  <Container maxWidth="sm" sx={{py: 12}} disableGutters>
    <Paper sx={{position: 'relative'}}>
      <Box
        sx={{
          ml: 'auto',
          mr: 'auto',
          position: 'relative',
          top: -SIDE / 2,
          mb: `-${SIDE / 2}px`,
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          width: SIDE,
          height: SIDE,
          borderRadius: '50%',
          backgroundColor: 'white',
          border: '2px solid lightgrey',
        }}>
        <img style={{width: '80%'}} src={welcomeLogo} alt="welcome logo" />
      </Box>
      <Box sx={{p: 8, pt: 4}}>{children}</Box>
    </Paper>
  </Container>
);

export default AuthPanel;
